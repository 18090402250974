<script lang="ts">
	import Icon from "./Icon.svelte";
	import arrowLeft from "../../../core/assets/icons/filled-arrow-left.svg?raw";
	import arrowRight from "../../../core/assets/icons/filled-arrow-right.svg?raw";
	import { onMount } from "svelte";

	export let visibleSlides: number[];
	export let itemsLength: number;
	export let currentSlide: number;
	export let customBulletCount: number | undefined = undefined;

	let containerClass = "";
	export { containerClass as class };

	let bulletsCount = 3;

	function checkBulletsCount(): void {
		if (customBulletCount !== undefined) {
			bulletsCount = 1;
		}
		bulletsCount = window.innerWidth < 1440 ? 1 : 3;
	}

	onMount(() => {
		checkBulletsCount();
		window.addEventListener("resize", checkBulletsCount);
		return () => {
			window.removeEventListener("resize", checkBulletsCount);
		};
	});

	$: startOffset = currentSlide + (bulletsCount - 1) === itemsLength - 1 ? 2 : currentSlide > 1 ? 1 : 0;
	$: start = Math.max(
		Math.min(
			currentSlide + (bulletsCount - 1) >= itemsLength ? itemsLength - 5 : currentSlide - startOffset,
			itemsLength - 3,
		),
		0,
	);
	$: end = Math.min(start + 5, itemsLength);
	$: realStart = end - start < 5 ? end - 5 : start;

	function calculateOffset(): number {
		const lastVisibleSlide = currentSlide + bulletsCount - 1;
		if (bulletsCount === 1 && currentSlide === 0) {
			return 1;
		}
		if (bulletsCount === 1 && currentSlide >= itemsLength - 2) {
			return 1;
		}
		if (bulletsCount === 1 || lastVisibleSlide < itemsLength - 1) {
			return 0;
		}
		if (lastVisibleSlide === itemsLength || lastVisibleSlide === itemsLength - 1) {
			return 1;
		}
		return 2;
	}

	$: bulletColorClass = (index: number, withoutOffset: boolean) => {
		const isVisible = withoutOffset
			? visibleSlides.includes(index + 1)
			: visibleSlides.includes(index + realStart + calculateOffset());
		return isVisible ? "bg-tertiary" : "bg-gray hover:bg-gray-300";
	};
</script>

<div class="border-gray flex h-[4.5rem] border-y {containerClass}" data-glide-el="controls">
	<button
		aria-label="Posunout vlevo"
		data-glide-dir="<"
		class="border-gray text-secondary {containerClass.includes('border-l-0') && 'border-l-0'}
			hover:border-tertiary hover:text-tertiary group flex min-w-10 grow-[1] items-center justify-center
			border-x border-r-0 bg-white hover:border"
	>
		<Icon icon={arrowLeft} class="h-4 w-4 transition-transform duration-200 ease-out group-hover:scale-[130%]" />
	</button>

	<div
		class="glide__bullets border-gray flex grow-[3] flex-wrap items-center justify-center gap-x-2 border-x bg-white px-2"
		data-glide-el="controls[nav]"
	>
		{#if itemsLength <= 5}
			{#each new Array(itemsLength) as _, index}
				<button
					aria-label="Posunout na pozici {index + 1}"
					class="glide__bullet h-[.625rem] w-[.625rem] rounded-full {bulletColorClass(index, true)}"
					data-glide-dir="={index}"
				/>
			{/each}
		{:else}
			{#each new Array(itemsLength).slice(realStart, end) as _, index}
				<button
					aria-label="Posunout na pozici {index + 1}"
					class="glide__bullet h-[.625rem] w-[.625rem] rounded-full {bulletColorClass(index, false)}"
					data-glide-dir="={index + realStart}"
				/>
			{/each}
		{/if}
	</div>

	<button
		aria-label="Posunout vpravo"
		data-glide-dir=">"
		class="text-secondary border-gray hover:border-tertiary hover:text-tertiary group flex min-w-10 grow-[1] items-center justify-center border-x border-l-0 bg-white hover:border"
	>
		<Icon icon={arrowRight} class="h-4 w-4 transition-transform duration-200 ease-out group-hover:scale-[130%]" />
	</button>
</div>
